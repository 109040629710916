'use client';
import { Transition, Typography } from 'components';
import { MaintenanceClipArt } from 'lib/ClipArt';

export default function Maintenance() {
  return (
    <div className="flex h-[100dvh] flex-1 flex-col items-center justify-center bg-tertiary-900">
      <div className="flex flex-col items-center justify-center">
        <Transition delay={0.2} className="h-full">
          <div className="mt-10 w-full ">
            <MaintenanceClipArt />
          </div>
        </Transition>
        <Transition delay={0.6} className="h-full">
          <div className="mt-10 flex max-w-2xl flex-col items-center">
            <div>
              <Typography variant="light3" className="text-tertiary-200">
                <div className="text-justify text-last-center">
                  We are currently performing maintenance to enhance your experience. The system
                  will be back online and available for use{' '}
                  <strong className="font-semibold text-tertiary-100">in soon</strong>.
                </div>
              </Typography>
            </div>
            <div>
              <Typography variant="light3" className="mt-5 text-tertiary-200">
                Thank you for your patience and understanding.
              </Typography>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}
