'use client';

import { createContext, FC, PropsWithChildren, RefObject, useContext, useRef } from 'react';
type TMenuContext = {
  refMenu: RefObject<HTMLInputElement> | null;
  handleScrollTop: () => void;
};

const MenuContext = createContext<TMenuContext>({
  refMenu: null,
  handleScrollTop: () => {}
});

const useMenuContext = (): TMenuContext => useContext(MenuContext);

const MenuProvider: FC<PropsWithChildren<unknown>> = ({ children }): JSX.Element => {
  const refMenu = useRef<HTMLInputElement>(null);

  const handleScrollTop = () => {
    if (refMenu.current) {
      refMenu.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <MenuContext.Provider value={{ handleScrollTop, refMenu }}>{children}</MenuContext.Provider>
  );
};

export { MenuProvider, useMenuContext };
