'use client';

import Maintenance from 'components/Maintenance';
import { MAINTENANCE_MODE_PERSISTED_STATE } from 'constants/persistedStates';
import usePersistentContext from 'hooks/usePersistenceState';
import { ReactNode, useEffect } from 'react';

type TMaintenanceScreen = {
  children?: ReactNode;
};

type TMaintenanceMode = 'true' | 'false' | undefined | null;

const MaintenanceScreen = (props: TMaintenanceScreen) => {
  const [maintenanceMode, setMaintenanceMode] = usePersistentContext<TMaintenanceMode>(
    MAINTENANCE_MODE_PERSISTED_STATE
  );

  const maintenanceModeResult = String(maintenanceMode)?.toLowerCase();
  const maintenanceModeEnv = String(
    process.env.NEXT_PUBLIC_MAINTENANCE_MODE?.toLowerCase() === 'true'
  );

  const startMaintenanceTime = String(process.env.NEXT_PUBLIC_START_MAINTAINANCE_TIME);
  const nowTime = new Date().toISOString();

  const isValidISO = (dateString: string) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime()) && date.toISOString() === dateString;
  };

  useEffect(() => {
    if (isValidISO(startMaintenanceTime) && new Date(startMaintenanceTime) < new Date(nowTime))
      setMaintenanceMode('true');
    else setMaintenanceMode(maintenanceModeEnv as TMaintenanceMode);
  }, [
    maintenanceModeEnv,
    maintenanceModeResult,
    nowTime,
    setMaintenanceMode,
    startMaintenanceTime
  ]);

  return <>{maintenanceModeResult === 'true' ? <Maintenance /> : <>{props?.children}</>}</>;
};

export default MaintenanceScreen;
