'use client';

import { IntlError } from 'next-intl';
import { notifyException } from 'services/appsignal';
import { ESpansException } from 'types/ESpans';

export const onErrorMessage = (error: IntlError) => {
  const info = {
    ...error,
    code: error.code,
    message: `Key: ${error.originalMessage} 

${error.message}`
  };

  notifyException(error, ESpansException.IntlErrorException, info);
};

export function getMessageFallback(info: { error: IntlError; key: string; namespace?: string }) {
  const { error, ...params } = info;

  notifyException(info.error, ESpansException.IntlFallbackException, params);

  return process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? ''
    : String(info.key).toLocaleUpperCase();
}
