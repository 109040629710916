import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { Persister } from '@tanstack/react-query-persist-client';

const persister =
  typeof window !== 'undefined'
    ? createSyncStoragePersister({
        storage: window.localStorage
      })
    : ({} as Persister);

export default persister;
