'use client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { IconToast } from 'components';
import { Flip, ToastContainer } from 'react-toastify';
import persister from 'services/persister';
import queryClient from 'services/queryClient';
import MaintenanceScreen from './MaintenanceScreen';

export default function RootLayoutContext({ children }: any) {
  return (
    <>
      <ToastContainer
        autoClose={10000}
        closeOnClick={false}
        position="top-center"
        hideProgressBar={false}
        newestOnTop
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        draggable={false}
        theme="light"
        icon={IconToast}
        transition={Flip}
      />
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister, maxAge: Infinity }}
      >
        <div className="flex w-full bg-white dark:bg-quaternary-100">
          <MaintenanceScreen>{children}</MaintenanceScreen>
        </div>
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
      </PersistQueryClientProvider>
    </>
  );
}
