export const isAuthPageUtil = (pathname: string) => {
  const isAuthPage = pathname.split('/')?.at(2) === 'auth';

  return isAuthPage;
};

export const isUnsubscribePageUtil = (pathname: string) => {
  const isAuthPage = pathname.split('/')?.at(2) === 'unsubscribe';

  return isAuthPage;
};

export const isHubspotCallbackUtil = (pathname: string) => {
  const isHubspotCallback = pathname.indexOf('/external/hubspot/callback') !== -1;

  return isHubspotCallback;
};
